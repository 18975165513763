
export default {
    namespaced: true,  
    state: {

    },
    getters: {
    },
    actions: {

    },
    mutations: {
        SOCKET_CONNECT(state) {
           
        },
      
        SOCKET_DISCONNECT(state) {
;
        },
      
        SOCKET_MESSAGECHANNEL(state, message) {
        }
    }
}