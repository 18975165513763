import Repository from '../Repository'

const resource = '/users'
export default {
    fetch(){
        return Repository.get(`${resource}/admin-manager`) 
    },
    fetchReports(params){
        return Repository.get(`${resource}/reports`)
    },
    editPaypal(params){
        return Repository.post(`${resource}/edit-paypal`,params)
    },
    saveSettings(data){
        return Repository.post(`${resource}/save-settings`,data)
    },
    getLogs(){
        return Repository.get(`${resource}/logs`)
    },
    getPlan(){
        return Repository.get(`${resource}/plan-report`)
    },

    getPlanReport(){
        return Repository.get(`plans/report`)
    }
    
}