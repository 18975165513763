import Repository from '../Repository'

const resource = '/a/plans'
export default {
    fetch(){
        return Repository.get(`${resource}`) 
    },
    fetchID(){
        return Repository.get(`${resource}/byid`) 
    },
    addAccount(params){
        let a = Object.assign({},params,)
        a.proxies = a.proxies.split('\n')
        return Repository.post(`${resource}`,a)
    },
    addPort(params){
        let p = params.split('\n')
        return Repository.post(`${resource}`,{proxies: p})
    },
    editAccount(id,params){
        let p = Object.assign({},params)
        p.proxies = p.proxies.split('\n')
        p.ip =p.ip.split(',')
        return Repository.post(`${resource}/edit/${id}`,p)
    },
    editPort(id,params){
        return Repository.post(`${resource}/edit/${id}`,params)
    },
    setActive(id,params){
        return Repository.post(`${resource}/active/${id}`,params)
    },
    deleteAccount(id){
        return Repository.delete(`${resource}/${id}`)
    },
    
}