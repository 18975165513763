import Repository from '../Repository'

const resource = '/alerts'
export default {
    fetchAlerts(){
        return Repository.get(`${resource}/admin`) 
    },
    createAlert(){
        return Repository.post(`${resource}`)
    },
    deleteAlert(id){
        return Repository.delete(`${resource}/${id}`)
    },
    saveAlert(id,params){
        return Repository.put(`${resource}/${id}`,params)
    }
    
}