import Repository from '../Repository'

const resource = '/users/payments'
export default {
    fetch(){
        return Repository.get(`${resource}`) 
    },
    createPayment(params){
        return Repository.post(`${resource}`,params)
    },
    changePassword(params){
    }
}