import Repository from '../Repository'

const resource = '/users'
export default {
    fetch(){
        return Repository.get(`${resource}/all-users`) 
    },
    fetchPlanInfo(){
        return Repository.get(`${resource}/all-plan-info`) 
    },
    fetchById(id){
        return Repository.get(`${resource}/info/${id}`) 
    },
    fetchPlanById(id){
        return Repository.get(`/plans/fetch/${id}`) 
    },
    fetchPortsById(id){
        return Repository.get(`${resource}/ports/${id}`) 
    },
    fetchPayment(id){
        return Repository.get(`${resource}/payments/${id}`) 
    },
    statusZone(id,params){
        return Repository.put(`${resource}/zones/${id}`,params) 
    },
    updatePlan(id,params){
        return Repository.put(`/plans/update/${id}`,params) 
    },
    handlePayment(id,params){
        return Repository.put(`${resource}/payments/${id}`,params) 
    },
    clonePort(id,params){
        return Repository.post(`${resource}/ports/clone/${id}`,params) 
    },
    addUser(data){
        return Repository.post(`${resource}`,data) 
    },
    addMoreBW(id,bw){
        return Repository.post(`${resource}/bw/${id}`,bw)
    },
    editUser(id,data){
        return Repository.post(`${resource}/update/${id}`,data) 
    },
    activeUser(id,data){
        return Repository.put(`${resource}/active/${id}`) 
    },
    activeTestMode(id){
        return Repository.post(`plans/test-mode/${id}`) 
    },
    blockUser(id){
        return Repository.put(`${resource}/block-user/${id}`)
    },
    deleteUsers(id){
        return Repository.delete(`${resource}/${id}`)
    },
    addfund(id,amount){
        return Repository.post(`${resource}/add-funds`,{
            id: id,
            amount: parseFloat(amount)
        })
    },
    getSubs(id){
        return Repository.get(`a/subscriptions/${id}`)
    },
    fetchBilling(id){
        return  Repository.get(`${resource}/billings/${id}`)
    },
    fetchPorts(id){
        return  Repository.get(`${resource}/ports/${id}`)
    },
    deleteUnValidPort(ids){
        return  Repository.post(`${resource}/ports/delete`,{ids: ids})
    },
    deletePort(id){
        return  Repository.delete(`${resource}/ports/${id}`)
    },
    deleteAllPort(uid,ids){
        return  Repository.post(`${resource}/ports/delete-all/${uid}`,{ids: ids})
    }
    
}