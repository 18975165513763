import axios from 'axios'

//const baseDomain = 'http://127.0.0.1:3000'
const baseDomain = 'https://sserver.zios.tools'
const baseUrl = `${baseDomain}`


let token = localStorage.getItem('token') || ''


export default axios.create({
    baseURL: baseUrl,
    timeout: 250000,
    headers: { Authorization: token }
})

//326vibsG772c
