import Repository from '../Repository'

const resource = '/plans'
export default {
    fetch(){
        return Repository.get(`${resource}`) 
    },
    fetchAll(){
        return Repository.get(`${resource}/all`) 
    },
    buyBW(data){
        return Repository.post(`${resource}`,data)
    },
    setRenew(id){
        return Repository.put(`${resource}/auto-renew/${id}`)
    },
    buyPlan(data){
        return Repository.post(`${resource}`,data)
    },
    getKey(planId){
        return Repository.get(`${resource}/get-key/${planId}`)
    },
    addKey(data){
        return Repository.post(`${resource}/add-key`,data)
    },

    resetKey(id){
        return Repository.put(`${resource}/reset-key/${id}`)
    },

    reactivePlan(id){
        return Repository.put(`${resource}/re-active/${id}`)
    },

    enableTestMode(){
        return Repository.post(`${resource}/test-mode`)
    }
}