import Repository from '../Repository'

const resource = '/users/billings'
export default {
    fetch(){
        return Repository.get(`${resource}`) 
    },
    fectchById(id){
        return Repository.post(`${resource}/${id}`)
    },
    changePassword(params){
    }
}