import Repository from '../Repository'

const resource = '/u/nport'
export default {
    fetch(){
        return Repository.get(`${resource}`) 
    },
    addPort(data){
        return Repository.post(`${resource}`,{data: data})
    },

    createWip(id){
        return Repository.post(`${resource}/wip`,{id: id})
    },
    renewWip(id){
        return Repository.put(`${resource}/wip/${id}`)
    },
    saveWip(data){
        return Repository.post(`${resource}/wip/save`,{data: data})
    },
    getWip(){
        return Repository.get(`${resource}/wip`)
    },
    getWipLog(data){
        return Repository.post(`${resource}/wip/logs`,data)
    },
    enableLog(id,en){
        return Repository.put(`${resource}/wip/${id}/logs`,{enable: en})
    },
    clearLog(id){
        return Repository.delete(`${resource}/wip/${id}/logs`)
    },
    deleteWip(id){
        return Repository.delete(`${resource}/wip/${id}`)
    },
    editPort(data){
        return Repository.put(`${resource}`,{data: data})
    },
    clonePort(id){
        return Repository.post(`${resource}/clone/`,id)
    },
    changeKeyport(data){
        return Repository.put(`${resource}/keyport`,{data: data})
    },
    changeGroupBypass(data){
        return Repository.put(`${resource}/group-bypass`,{data: data})
    },
    changePortName(data){
        return Repository.post(`${resource}/change/name`,data)
    },
    changeIp(id){
        return Repository.get(`${resource}/ip/${id}`)
    },
    changeIpMulti(ids){
        return Repository.post(`${resource}/ip`,ids)
    },
    changeGeo(data){
        return Repository.put(`${resource}/geo`,{data: data})
    },
    changeRotate(data){
        return Repository.put(`${resource}/rotate`,{data: data})
    },

    changeServices(data){
        return Repository.put(`${resource}/services`,{data: data})
    },
    changeStatus(data){
        return Repository.put(`${resource}/status`,{data: data})
    },
    deletePort(data){
        return Repository.post(`${resource}/delete`,{ids: data})
    },
    changeSettings(data){
        return Repository.put(`${resource}/change-settings`,data)
    },

    getSettings(data){
        return Repository.put(`${resource}/port-settings`,data)
    }
}