import Repository from './Repository'

const resource = '/auth'
export default {
    login(params){
        return Repository.post(`${resource}/signin`,params) 
    },
    memberLogin(params){
        return Repository.post(`${resource}/member/login`,params) 
    },
    register(params){
        return Repository.post(`${resource}/signup`,params)
    },
    forgotPassword(params){
        return Repository.post(`${resource}/forgot-password`,params)
    },
    confirmReset(params){
        return Repository.put(`${resource}/forgot-password`,params)
    }
    
}